import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ideabizVerfyPhonePin } from "../../Screens/Subscription/service";
import Translate from "../MultiLanguage/Translate";

const IdeabizMobilePinModal = ({ appInfo, ideabizToken, setPinVerifyModal, serverRef, setPurchaseModal }) => {

  const [input, setInput] = useState("");
  const [errors, setErrors] = useState({});
  const [submitButtonActive, setSubmitButtonActive] = useState(true);

  const validation = async () => {
    let errors = {};
    let formIsValid = true;
    if (!input.trim()) {
      formIsValid = false;
      errors.pin = "PIN is required.";
    }
    setErrors(errors);
    return formIsValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const isValid = await validation()
      if (isValid) {
        setSubmitButtonActive(false);
        const inputData = {
          token: ideabizToken,
          pin: input,
          serverRef: serverRef
        }
        const response = await ideabizVerfyPhonePin(appInfo, inputData);
        if (response?.status === 200 && response?.data.success == true) {
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          setTimeout(() => {
            setPinVerifyModal(false)
            setPurchaseModal(true)
          }, 3000);
        } else {
          let errors = {};
          errors.pin = response?.data?.message;
          setErrors(errors);
          setSubmitButtonActive(true)
          // setTimeout(() => {
          //   setPinVerifyModal(false)
          //   setPurchaseModal(true)
          // }, 3000);
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
      setSubmitButtonActive(true)
    }
  };

  return (
    <div className="otpModal">
      <ToastContainer />
      <div className="overlayOtp"></div>
      <div className="otpContainer">
        {/* <h4>Welcome to CeyFliX</h4>
        <h6>Your monthly subscription is Rs {ideabizPlan?.ideabiz_price}+ Tax.</h6> */}
        <label htmlFor="otp"><Translate textkey={'enter_code'} /></label>
        <input
          type="number"
          id="pin"
          value={input || ""}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        {errors?.pin && <span className="error">{errors.pin}</span>}
        <div className="buttonContainer" onClick={(e) => submitButtonActive && submitHandler(e)}>
          <div className="background"></div>
          <button disabled={!submitButtonActive}><Translate textKey={'submit'} /></button>
        </div>
      </div>
    </div>
  );
};

export default IdeabizMobilePinModal;
