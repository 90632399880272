import React, { useEffect, useState } from "react";
import { Epg, Layout } from "planby";
import { useSelector } from "react-redux";

// Import hooks
import { useApp } from "./useApp";

// Import components
import { Timeline, ChannelItem, ProgramItem } from "./components";

function LiveEpgContainer({ selectedCategory }) {

    const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const { isLoading, getEpgProps, getLayoutProps, channels } = useApp({ selectedCategory });
  const [filteredChannels, setFilteredChannels] = useState([]);
  useEffect(() => {
      if (selectedCategory && selectedCategory) {
      
      const updatedChannels = channels.filter(channel =>
        channel?.categories?.some(category => category?.category_id === selectedCategory?.category_id)
      );
      setFilteredChannels(updatedChannels);
    } else {
      setFilteredChannels(channels);
    }
    
    
  }, [selectedCategory, channels]);

  return (
    <div>
      <div style={{ height: "80vh", width: "100%" }}>
        <Epg isLoading={isLoading} {...getEpgProps()}>
          <Layout
            {...getLayoutProps({ channels: filteredChannels })}
            renderTimeline={(props) => <Timeline {...props} />}
            renderProgram={({ program, ...rest }) => (
              <ProgramItem key={program.data.id} program={program} {...rest} />
            )}
            renderChannel={({ channel }) =>
            (
              <ChannelItem  channel={channel} />
            )
          }
          isRTL={isRTL}
          />
        </Epg>
      </div>
    </div>
  );
}

export default LiveEpgContainer;
