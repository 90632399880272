import React from "react";
import Translate from "../../Components/MultiLanguage/Translate";
import { useSelector } from "react-redux";

const LandingScreeen = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const storeButtonHandler = (storeLink) => {
    window.location.href = storeLink
  }
  return (
    <div className={`landingScreen ${isRTL ? "rtl" : ""}`}>
      <div className="spotlight rightType">
        <div className="left">
          <h1 className="heading"><Translate textKey={'welcome_template_message'}/></h1>
          <p className="description">
          <Translate  textKey={'watch_movies_shows_message'}/>
          </p>
          <div className="buttonContainer">
            <div className="background"></div>
            <button><Translate textKey={'sign_to_template'}/></button>
          </div>
        </div>
        <div className="right">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>
      </div>

      <div className="watchNowSection leftType">
        <div className="left">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>

        <div className="right">
                <h1 className="heading"><Translate textKey={'welcome_template_message'} /></h1>
                <p className="description"><Translate textKey={'watch_movies_shows_message'} /></p>
                <div className="buttonContainer">
                    <div className="background"></div>
                    <button><Translate textKey={' Watch Now'} /></button>
                </div>
            </div>
      </div>

      <div className="other rightType">
        <div className="left">
          <h1 className="heading"><Translate  textKey={'welcome_template_message'} /></h1>
          <p className="description">
            <Translate textKey={'watch_movies_shows_message'} />
          </p>
          <div className="buttonContainer">
            <div className="background"></div>
            <button><Translate textKey={'sign_to_template'}/></button>
          </div>
        </div>
        <div className="right">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>
      </div>
      {(projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL || projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL || projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL || projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL || projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL || projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL) && 
        <div className="device">
        <div className="left">
            <h1 className="heading"><Translate textKey={'favorite_channel_message'} /></h1>
            <p className="description"><Translate textKey={'favorite_channel_description_message'} /></p>
        </div>
        <div className="right">
          <ul className="devices">
            {projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL) }}><span>Roku</span></li>}
            {projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL) }}><span>Apple TV</span></li>}
            {projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL) }}><span>Android TV</span></li>}
            {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL) }}><span>Android</span></li>}
            {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL) }}><span>IOS</span></li>}
            {projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL) }}><span>Amazon Fire</span></li>}

          </ul>
        </div>
      </div>
      }
      
    </div>
  );
};

export default LandingScreeen;
