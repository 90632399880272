import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Zoom from "@mui/material/Zoom";
import { useDispatch, useSelector } from "react-redux";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as ClipBoard } from "../../assets/Icons/clipBoard.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { checkOperatingSystem, convertDateAndTimeToLocal, getEventInfo, imageUrlCheck } from "../../utils/utils";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { ReactComponent as ShareIcon } from "../../assets/Icons/share.svg";

import parse from "html-react-parser";
import MainLoading from "../../Components/Loading/MainLoading";
import { authenticate, getPlayerToken, getUserSubscription, updateWatchlistData } from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import { useWindowWidth } from "@react-hook/window-size";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import TrailerModal from "../../Components/Modals/TrailerModal";
import LogoutAllModal from "../../Components/Modals/LogoutAllModal";
import { getEventMoreInfo } from "../../Redux/MoreInfo/eventMoreInfoSlice";
import Translate from "../../Components/MultiLanguage/Translate";
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction="down" ref={ref} {...props} />;
});
const EventDetailsScreenModal = ({ fetchHomeData, modalShow }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const isMoreInfoOther = useSelector((state) => state?.moreInfoOther?.value);
  const isEventMoreInfo = useSelector((state) => state?.eventMoreInfo?.value);

  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const isMoreInfo = useSelector((state) => state?.overlay?.value);
  const [showDetails, setShowDetails] = useState({});
  const [playerToken, setPlayerToken] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
  const [isSubscribedUser, setIsSubscribedUser] = useState();
  const [isFreeVideo, setIsFreeVideo] = useState();
  const [subscribeButton, setSubscribeButton] = useState();
  const [videoPlayer, setVideoPlayer] = useState();
  const [videoStarted, setVideoStarted] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const [logoutModal, setLogoutModal] = useState(false);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [open, setOpen] = useState(false);
  const [pageURL, setPageURL] = useState("");
  const [isShareActive, setIsShareActive] = useState(false);

  const windowSize = useWindowWidth();
  const scrollRef = useRef(null);
  const shareRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const navigate = useNavigate();
  let eventUniqueId = isEventMoreInfo?.eventId ? isEventMoreInfo?.eventId : eventId;
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {

    setPageURL(window.location.href);

    fetchUserSubscriptionDetails();
    return () => {
      handleReset();
    };
  }, []);
  useEffect(() => {
    if (location?.pathname === "/home") {
      handleClose();
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (isMoreInfoOther?.eventId) {
      eventUniqueId = isMoreInfoOther?.eventId;
    }
  }, [isMoreInfoOther?.eventId]);

  useEffect(() => {
    if (eventUniqueId) {
      setOpen(true);
      if (eventUniqueId !== "null" && eventUniqueId !== "undefined") {
        fetchEventInfo();
      }
    }
  }, [eventUniqueId]);


  useEffect(()=>{
    if(showDetails){
      if(Object.entries(showDetails).length > 0){
          if(projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true"){
            if(projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true"){
              let isSubscribedUser;
              if(showDetails?.subscriptions?.length>0){
                isSubscribedUser = userSubscriptionData?.data?.length > 0
                  ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                  : false;
                  if(isSubscribedUser === true){
                    setSubscribeButton(false)
                    setIsSubscribedUser(true)
                  }else{
                    setIsSubscribedUser(false)
                    setInitialVideoSubscriptions(showDetails?.subscriptions)
                    if(showDetails?.free_event === true){
                    setSubscribeButton(false)
                    }else{
                      setSubscribeButton(true)
                    }
                  }
              }else{
                setSubscribeButton(false)
                setIsSubscribedUser(true)
              }
            }else{
              setSubscribeButton(false)
              setIsSubscribedUser(true)
            }
          }else{
            if(userSubscriptionData?.login_needed === false){
              setSubscribeButton(false)
              setIsSubscribedUser(true)
            }
          }
      }
    }
  },[showDetails])


  const fetchEventInfo = async () => {
    try{
      const response = await getEventInfo(appInfo,eventUniqueId);
      setShowDetails(response?.data?.data)
      setLoading(false)
    }catch(err){
      setLoading(false)
    }
  }
 

  const handleClose = (type) => {
    document.body.className = "hideScroll";

    handleReset();
    setOpen(false);
    let path = location?.pathname
    if (path.split("/")[1]==="category") {
      setTimeout(() => {
        dispatch(
          getEventMoreInfo({
            isModal: false,
            eventId: null,
          })
        );
      }, 600);
    } else {
      if(type!=="showDetails"){
        navigate("/home");
      }
      setTimeout(() => {
        dispatch(
          getEventMoreInfo({
            eventMoreInfo: false,
          })
        );
      }, 500);
    }
    setTimeout(() => {
      setShowDetails(null);
    }, 600);
  };

  const handleReset = () => {
    setVideoPlayer(null);
    setIsMute(false);
    setVideoStarted(false);
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };

  const playVideo = async () => {
    if (showDetails?.type !== "UPCOMING") {
      navigate("/player",
        { state: { eventId: showDetails?.event_id } }
      );
    } else {
      toast.error("Event is not started yet")
    }
  };

  const redirectToLogin = () => {
    dispatch(
      getUser({
        user: null,
      })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate();
    navigate("/login", {
      state: { path: location?.pathname, showId: eventUniqueId },
    });
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };

  const handleSubscribe = (data) => {
    if (user) {
      localStorage.setItem("selectedSubId", data?.subscription_id);
      localStorage.setItem("selectedAmount", data?.price);
      localStorage.setItem("eventId", showDetails?.event_id);
      localStorage.setItem("vanityUrl", eventUniqueId);
      localStorage.setItem("eventType", showDetails?.type);
      navigate("/payment", { state: { subscription: { ...data, is_coupon: false } } });
    } else {
      handleClose();
      navigate("/login", {
        state: { path: "/event/" + eventUniqueId, showId: eventUniqueId },
      });
    }
  };

  const subscriptionCheckAndVideoPlay = async () => {
    if (user) {
      if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
        if (userSubscriptionData?.forcibleLogout === true) {
          setLogoutModal(true);
          setModalType({
            type: "logoutAll",
            heading: <Translate textKey={'reached_maximum_device_message'}/>,
          });
        } else if (userSubscriptionData?.session_expired === true) {
          setModalType({ type: "redirect" });
          setLogoutModal(true);
          setTimeout(() => {
            handleClose();
            redirectToLogin();
          }, 1000);
        } else {
          if (showDetails?.subscriptions?.length > 0) {
            let isSubscribedUser;
              isSubscribedUser =
                userSubscriptionData?.data?.length > 0
                  ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                  : false;
           
            if (isSubscribedUser === true) {
              handleClose();
              playVideo();
            } else {
              if (showDetails?.free_event === true) {
                handleClose();
                playVideo();
              } else {
                localStorage.setItem("eventId", showDetails?.event_id);
                localStorage.setItem("vanityUrl", eventUniqueId);
                localStorage.setItem("eventType", showDetails?.type);
                handleClose();

                navigate("/subscription", {
                  state: { eventId: showDetails?.event_id },
                });
              }
            }
          } else {
            if (showDetails) {
              handleClose();
              playVideo();
            }
          }
        }
      } else {
        handleClose();
        playVideo();
      }
    } else {
      handleClose();
      navigate("/login", {
        state: { path: "/event/" + eventUniqueId, showId: eventUniqueId },
      });
    }
  };

  const watchVideoHandler = async () => {
    if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
      subscriptionCheckAndVideoPlay();
    } else {
      if (userSubscriptionData?.login_needed === false) {
        handleClose();
        playVideo();
      } else if (userSubscriptionData?.login_needed === true) {
        setModalType({ type: "videoWatchLimitExpired" });
        setLogoutModal(true);
      } else {
        subscriptionCheckAndVideoPlay();
      }
    }
  };

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  const showDetailsRedirect = () => {
    handleClose("showDetails")
    navigate(`/show-details/${showDetails?.vanity_url}`)

  }

  if (loading) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        transitionDuration={600}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullScreen={windowSize > 980 ? false : true}
      >
        <DialogContent sx={{ p: 0 }} dividers={false}>
          <MainLoading />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      transitionDuration={600}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullScreen={windowSize > 980 ? false : true}
    >
      <DialogContent sx={{ p: 0 }} dividers={false}>
        <div className="detailsScreenModal" ref={scrollRef} onClick={() => setIsShareActive(false)}>
          {logoutModal && (
            <LogoutAllModal
              showId={eventUniqueId}
              appInfo={appInfo}
              setLogoutModal={setLogoutModal}
              modalType={modalType}
              videoDetails={selectedVideoDetails}
              subscriptionCheckAndVideoPlay={subscriptionCheckAndVideoPlay}
              handleClose={handleClose}
            />
          )}
          <div className="imgVideoContainer">
            <div className="closeButton">
              <button onClick={() => handleClose()}>
                <div className="icon">
                  <CloseIcon />
                </div>
              </button>
            </div>
            {imageUrlCheck(showDetails?.thumbnail_350_200) ?  (
              <img className="show" src={showDetails?.thumbnail_350_200} alt="Thumbnail" />
            ) : (
              <img
                className="show"
                src={`${process.env.REACT_APP_IMAGE_URL}${showDetails?.thumbnail_350_200}`}
                alt="Thumbnail"
              />
            )}

            <div className="showActions">
              <h1 className="title">{showDetails?.event_name}</h1>
              <div className="buttons">
                <div className="left">
                  {subscribeButton&& showDetails?.type!=="ENDED"  ? (
                    <button className="subscribe" onClick={() => watchVideoHandler()}>
                      <span><Translate textKey={'subscribe'}/></span>
                    </button>
                  ) :  showDetails?.type!=="UPCOMING"&& showDetails?.type!=="ENDED"&&(
                    <button className="play" onClick={() => watchVideoHandler()}>
                      <div className="icon">
                        <PlayIcon />
                      </div>
                      <span><Translate textKey={'play'}/></span>
                    </button>
                  )}
                 
                  {projectInfo?.projectConfig?.config?.SHOW_SHARE_BUTTON === "true" && (
                    <div className="share">
                      <button
                        className="shareButton"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsShareActive(!isShareActive);
                        }}
                      >
                        <ShareIcon />
                      </button>
                      <div className={isShareActive ? "active shareContainer" : "shareContainer"} ref={shareRef}>
                        <div className="facebookContainer" onClick={() => setIsShareActive(false)}>
                          {/* <div className="left"> */}
                          <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                            Facebook
                            <div className="icon">
                              <FacebookIcon />
                            </div>
                          </FacebookShareButton>
                          {/* </div> */}
                          {/* <div className="right">
                        <FacebookIcon />
                      </div> */}
                        </div>
                        <div className="twitterContainer" onClick={() => setIsShareActive(false)}>
                          <TwitterShareButton url={pageURL} className="twitter">
                            Twitter
                            <div className="icon">
                              <TwitterIcon />
                            </div>
                          </TwitterShareButton>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bottomVignette"></div>
          </div>
          {
            showDetails?.type ==="ENDED"&&
            <div className="eventEndedOverlay">
              <h2><Translate textKey={'the_event_ended_message'}/></h2>
              <p onClick={showDetailsRedirect}><Translate textKey={'click_here_to_watch'}/></p>
            </div>
          }
          {
            showDetails?.type !=="ENDED"&&
          <div className="info">
            <div className="metaData">
              <div className="left">
                <div className="top">
                  {
                    showDetails?.schedule_time&& showDetails?.type !=="ENDED"&&
                  <span className="duration">{convertDateAndTimeToLocal(showDetails?.schedule_time)}</span>
                  }
                  {/* {
                    showDetails?.type === "ENDED"&&
                  <span className="ended">Event ended <span onClick={()=>showDetailsRedirect()}>click here to watch</span></span>
                  } */}
                </div>
                {showDetails?.description ||
                !isSubscribedUser ||
                showDetails?.free_event === true  ? (
                  <div className="bottom">
                    {showDetails?.description && (
                      <p className="description">{parse(showDetails?.description?.split("\n")?.join("<br>"))}</p>
                    )}
                    {(!isSubscribedUser || showDetails?.free_event === true) && showDetails?.type !=="ENDED" ?
                      (showDetails?.subscriptions?.length > 0 || selectedVideoDetails?.free_video === true ? (
                        <div className="subscriptionContainer">
                          {initialVideoSubscriptions?.length > 0 &&
                            initialVideoSubscriptions?.map((item, index) => (
                              <button onClick={() => handleSubscribe(item)}>
                                <span>{item?.subscription_text}</span>
                              </button>
                            ))}
                          {showDetails?.free_event === true && (
                            <button className="free" onClick={() => watchVideoHandler()}>
                              <span>
                             <Translate textKey={'free'}/>

                              </span>
                            </button>
                          )}
                        </div>
                      ) : null):null
                    
                    }
                  </div>
                ) : null}
              </div>

              <div className="right">
                
               
                {showDetails?.categories?.length > 0 && (
                  <div className="categories">
                    <span className="heading"><Translate textKey={'categories'}/></span>
                    {showDetails?.categories?.map((item, index) => (
                      <span onClick={() => navigate(`/category/${item?.key}?career=${item?.key}`)} key={index}>
                        {item?.category_name}
                        {index + 1 !== showDetails?.categories?.length && ","}
                      </span>
                    ))}
                  </div>
                )}
               
              </div>
            </div>
          </div>
          }
        </div>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClose}>Subscribe</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default EventDetailsScreenModal;
