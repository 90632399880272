import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import {  useNavigate } from "react-router-dom";
import Translate from "../MultiLanguage/Translate";
const NoScheduleAvailableModal =()=>{
    const navigate = useNavigate();
    return(
        <div className="noscheduleContainer">
        <div className="overlayModal"></div>
        <div
            className="closeIconContainer"
            onClick={() => navigate("/home")}
          >
            <CloseIcon />
          </div>
            <div className="contents">
            <h1><Translate textKey={'no_schedule_available'} /></h1>
            </div>
      </div>
    )
}
export default NoScheduleAvailableModal;