import { createSlice } from '@reduxjs/toolkit';

const RTL_LANGUAGES = ['ar', 'he']; 

const initialState = {
  isRTL: RTL_LANGUAGES.includes(localStorage.getItem("selectedLanguageCode")),
};

const RTLSlice = createSlice({
  name: 'rtl',
  initialState,
  reducers: {
    setRTL: (state, action) => {
      state.isRTL = RTL_LANGUAGES.includes(action.payload);
    },
  },
});

export const { setRTL } = RTLSlice.actions;
export default RTLSlice.reducer;
