import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageModal } from '../../Redux/LanguageModal/languageModalSlice';
import { getLanguageList, updateUserLanguage } from '../../network/service';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../Redux/UserDetailsSlice/UserDetailsSlice';
import { useTranslation } from 'react-i18next';
import Translate from '../MultiLanguage/Translate';
import { authenticate } from "../../network/service";

const LanguageModal = ({ type }) => {
  const { i18n } = useTranslation();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);

  const selectedLanguageData = useSelector((state) => state?.languageModal?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);

  const [selectedValue, setSelectedValue] = useState({short_code: 'en'})
  const [initialSelectedValue, setInitialSelectedValue] = useState()
  const dispatch = useDispatch();
  const [options, setOptions] = useState()
  const location = useLocation()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken
  }
  const navigate = useNavigate()
  const redirectURL = localStorage.getItem("redirectURL")
  const getOptionLabel = (option) => option.language_name;
  const getOptionValue = (option) => option.language_id;
  const getOptionCode = (option) => option.short_code;

  useEffect(() => {

    fetchOptions().then((data) => {

      setOptions(data)
      if (selectedLanguageData && selectedLanguageData?.selectedLanguage) {
        const initialValue = data?.filter((option) => option?.language_id === selectedLanguageData?.selectedLanguage)
        setSelectedValue(initialValue[0])
      }
      
      else {
        let defaultValue = data?.filter((option) => option?.language_id === 39)
        setSelectedValue(defaultValue[0])
      }
    })


    return () => localStorage.removeItem("redirectURL")
  }, [selectedLanguageData])

  
  

  useEffect(() => {
    if (selectedValue && selectedValue.language_id) {
      localStorage.setItem('selectedLanguageId', selectedValue.language_id);
      localStorage.setItem('selectedLanguageName',selectedValue.language_name);
      localStorage.setItem('selectedLanguageCode',selectedValue.short_code);
     
    } else {
      console.error(' undefined');
    }
  }, [selectedValue]);
  


  const closeHandler = () => {
    dispatch(
      getLanguageModal({
        languageModal: {
          isOpen: false,
          selectedLanguage: null
        }
      })
    )
  }

  const fetchOptions = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await getLanguageList(appInfo)
        resolve(response?.data?.data)
      } catch (err) {

      }
    });


  };

  const handleChange = (selectedValue) => {

    setSelectedValue(selectedValue)

    if (selectedValue?.language_id) {

      try {
        i18n.changeLanguage(selectedValue?.short_code);
        i18n.addResourceBundle(selectedValue?.short_code, 'translation', projectInfo?.projectConfig?.config?.LANGUAGE_KEYWORDS, true, true);
      } catch (error) {
        i18n.init({
          lng: 'en',
          resources: {},
        });
        i18n.changeLanguage('en');
        i18n.addResourceBundle('en', 'translation', projectInfo?.projectConfig?.config?.LANGUAGE_KEYWORDS, true, true);
      }
    }

  }

  const submitButtonHandler = () => {
    if (selectedValue?.language_id) {


      // try {
      //   i18n.changeLanguage(selectedValue?.short_code);
      //   i18n.addResourceBundle(selectedValue?.short_code, 'translation', projectInfo?.projectConfig?.config?.LANGUAGE_KEYWORDS, true, true);
      // } catch (error) {
      //   i18n.init({
      //     lng: 'en',
      //     resources: {},
      //   });
      //   i18n.changeLanguage('en');
      //   i18n.addResourceBundle('en', 'translation', projectInfo?.projectConfig?.config?.LANGUAGE_KEYWORDS, true, true);
      // }
      updateUserLanguageData()
    } else {
      toast.error(<Translate textKey={'select_language'}/>, {
        position: "top-center"
      })
    }
  }

  const updateUserLanguageData = async () => {
    try {
      const response = await updateUserLanguage(appInfo, selectedValue?.language_id)

      const authresponse = await authenticate(appInfo);

      localStorage.setItem('selectedLanguageCode',authresponse?.data?.user_language?.short_code);
      localStorage.setItem('selectedLanguageId',authresponse?.data?.user_language?.language_id);
       dispatch(
        getLanguageModal({
          languageModal: {
            selectedLanguageCode: authresponse?.data?.user_language?.short_code
          }
        }) )
     
      if (response?.status === 200) {
        dispatch(
          getUserDetails({
            userDetails: { ...userDetails, language_id: selectedValue?.language_id, short_code: selectedValue?.short_code }
          })
        )
        //   dispatch(
        //     getLanguageModal({
        //         languageModal:{
        //             isOpen:false,
        //             selectedLanguage:selectedValue
        //         }
        //     }) 
        // )
        if (type === "root") {
          if (redirectURL) {
            navigate(redirectURL, { replace: true })
          } else {
            navigate("/home")
          }
        } else {

          dispatch(
            getLanguageModal({
              languageModal: {
                isOpen: false,
                selectedLanguage: selectedValue?.language_id,
                selectedLanguageCode: selectedValue?.short_code,
              }
            })
          )
        }
        toast.success(response?.data?.message, {
          position: "top-center"
        })
      }
    } catch (err) {

    }

  }

  return (
    <div className='languageModal'>
      <div className={type === "root" ? "overlayModal root" : "overlayModal"}></div>
      {/* <div className="closeIconContainer" onClick={closeHandler}>
        <CloseIcon />
      </div> */}

      <div className="contents">
        <h1><Translate textKey={'choose_your_language'} /></h1>
        <div className="inputContainer">
          <Select
            className="basic-single"
            classNamePrefix="select"
            value={selectedValue}
            isSearchable={true}
            name="color"
            options={options}
            onChange={handleChange}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            getOptionCode={getOptionCode}
          />
        </div>
        <div className="buttonContainer" onClick={submitButtonHandler}>
          <button><Translate textKey={'ok'} /></button>
        </div>
      </div>
    </div>
  )
}

export default LanguageModal
